.custom-footer {
  color: #999;
  background: #2b2b40;

  position: relative;
  bottom: 0;

  padding: 3em 3em;

  .custom-copyright {
    text-align: center;
  }
}
