.custom-hero {
  color: #f5f5f5;
  background: linear-gradient(to bottom, #01558f 0%, #13334c 100%) no-repeat #a05fb7;

  padding: 5em 0 10em 0;

  text-align: center;
}

//.custom-main {
//  padding-top: 5em;
//  padding-bottom: 5em;
//}

.custom-card-title {
  img {
    display: inline-block;
  }
  h5 {
    display: inline-block;
  }
}
