$body-bg: #f5f5f5;
$breadcrumb-bg: transparent;
$enable-responsive-font-sizes: true;
$navbar-dark-hover-color: #f5f5f5;
$carousel-control-color: #000 !default;

@import
  "bootstrap",
  "_layouts/footer", "_layouts/home", "_layouts/page-product",
  "_includes/header",
  "_blockquote", "_feature_cards", "_post_cards";
