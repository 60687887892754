blockquote {

  $blockquote-color: #00558f;

  border-left: 5px solid $blockquote-color;
  border-top: 1px solid $blockquote-color;
  border-bottom: 1px solid $blockquote-color;

  p{ margin: 16px 16px; }
}
